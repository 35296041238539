import React from "react"
import styled from "styled-components"

const ContactContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 12px;
    svg {
      width: 16px;
      path {
        fill: #fdfdfd;
      }
    }
    p {
      text-align: left;
      margin-left: 4px;
      color: #fdfdfd;
    }
  }
`
const DetailsContainer = styled.div.attrs({
  id: "Details",
})`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 24px 16px;
  background-color: #305244;
  h4 {
    color: #fdfdfd;
  }
  #DetailsHeader {
    text-align: center;
    color: #fdfdfd;
    margin-bottom: 16px;
  }
  @media screen and (min-width: 950px) {
    margin-top: 96px;
    #DetailsHeader {
      text-align: left !important;
      margin-top: 0;
    }
  }
`

const Details = () => (
  <DetailsContainer>
    <h2 id="DetailsHeader">Other ways to get in touch with us</h2>
    <ContactContainer>
      <h4>Batangas</h4>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.99992 1.33325C5.41992 1.33325 3.33325 3.41992 3.33325 5.99992C3.33325 9.49992 7.99992 14.6666 7.99992 14.6666C7.99992 14.6666 12.6666 9.49992 12.6666 5.99992C12.6666 3.41992 10.5799 1.33325 7.99992 1.33325ZM7.99992 7.66659C7.07992 7.66659 6.33325 6.91992 6.33325 5.99992C6.33325 5.07992 7.07992 4.33325 7.99992 4.33325C8.91992 4.33325 9.66659 5.07992 9.66659 5.99992C9.66659 6.91992 8.91992 7.66659 7.99992 7.66659Z"
            fill="#FDFDFD"
          />
        </svg>
        <p>Brgy. Dumantay, Batangas City, Philippines 4200</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 2.66675H2.66659C1.93325 2.66675 1.33992 3.26675 1.33992 4.00008L1.33325 12.0001C1.33325 12.7334 1.93325 13.3334 2.66659 13.3334H13.3333C14.0666 13.3334 14.6666 12.7334 14.6666 12.0001V4.00008C14.6666 3.26675 14.0666 2.66675 13.3333 2.66675ZM13.3333 5.33341L7.99992 8.66675L2.66659 5.33341V4.00008L7.99992 7.33341L13.3333 4.00008V5.33341Z"
            fill="black"
          />
        </svg>
        <p>vens_solis@yahoo.com</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+(043) 740-7969</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+63 917-505-2053</p>
      </div>
    </ContactContainer>
    <ContactContainer>
      <h4>Mindoro</h4>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.99992 1.33325C5.41992 1.33325 3.33325 3.41992 3.33325 5.99992C3.33325 9.49992 7.99992 14.6666 7.99992 14.6666C7.99992 14.6666 12.6666 9.49992 12.6666 5.99992C12.6666 3.41992 10.5799 1.33325 7.99992 1.33325ZM7.99992 7.66659C7.07992 7.66659 6.33325 6.91992 6.33325 5.99992C6.33325 5.07992 7.07992 4.33325 7.99992 4.33325C8.91992 4.33325 9.66659 5.07992 9.66659 5.99992C9.66659 6.91992 8.91992 7.66659 7.99992 7.66659Z"
            fill="#FDFDFD"
          />
        </svg>
        <p>Kaimito St., Lalud, Calapan City Oriental Mindoro</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 2.66675H2.66659C1.93325 2.66675 1.33992 3.26675 1.33992 4.00008L1.33325 12.0001C1.33325 12.7334 1.93325 13.3334 2.66659 13.3334H13.3333C14.0666 13.3334 14.6666 12.7334 14.6666 12.0001V4.00008C14.6666 3.26675 14.0666 2.66675 13.3333 2.66675ZM13.3333 5.33341L7.99992 8.66675L2.66659 5.33341V4.00008L7.99992 7.33341L13.3333 4.00008V5.33341Z"
            fill="black"
          />
        </svg>
        <p>king.alton@yahoo.com</p>
      </div>
      <div>
        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.7533 9.8 10.4933 9.86 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
            fill="black"
          />
        </svg>
        <p>+(043) 441-3032</p>
      </div>
    </ContactContainer>
  </DetailsContainer>
)

export default Details

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Details from "../components/details"
import { Helmet } from "react-helmet"
import ReCAPTCHA from "react-google-recaptcha"

const FormContainer = styled.form.attrs({
  id: "ContactLong",
})`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #305244;
  padding: 0 24px 48px;
  h2 {
    margin-top: 32px;
    font-size: 32px;
    text-align: center;
    color: #fdfdfd;
  }
  p {
    margin-top: 8px;
    text-align: center;
    color: #fdfdfd;
  }
  h2,
  p {
    width: 100%;
  }
  @media screen and (min-width: 450px) {
    padding: 32px 32px;
  }
  @media screen and (min-width: 500px) {
    padding: 32px 48px;
  }
  @media screen and (min-width: 650px) {
    padding: 48px 64px;
  }
  @media screen and (min-width: 800px) {
    padding: 48px 72px;
  }
  @media screen and (min-width: 950px) {
    padding: 48px 80px;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "header header"
      "subheader subheader"
      "form details";
    h2 {
      grid-area: header;
      justify-self: center;
      margin-top: 0;
    }
    p {
      grid-area: subheader;
      justify-self: center;
    }
    #Details {
      grid-area: details;
    }
  }
  @media screen and (min-width: 1050px) {
    padding: 64px 96px;
    p {
      width: 70%;
    }
  }
`

const FormInputContainer = styled.div`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: max-content;
  @media screen and (min-width: 950px) {
    margin-top: 96px;
    background-color: #fdfdfd;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    grid-area: form;
    padding: 8px 24px 24px;
  }
  @media screen and (min-width: 1050px) {
    padding: 16px 32px 32px;
  }
`

const InputContainer = styled.div`
  width: 100%;
  label {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    input,
    textarea {
      margin-top: 4px;
      width: 100%;
      resize: none;
    }
    span span {
      color: #d90207;
    }
  }
  @media screen and (min-width: 950px) {
    label {
      color: #305244;
      input,
      textarea {
        border: 1px solid #c5c5c5;
        box-sizing: border-box;
        border-radius: 10px;
      }
    }
  }
`

const SubmitButton = styled.div`
  width: 100%;
  margin-top: 16px;
  input {
    width: 100%;
    padding: 12px 24px;
    background: #7bc8a8;
    border-radius: 10px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    color: #fdfdfd;
    border: none;
    height: auto;
    margin-top: 6px;
  }
  @media screen and (min-width: 700px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 800px) {
    font-size: 1.125rem;
  }
`

export default class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    captchaVal: false,
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleCaptchaVerification = event => {
    this.setState({
      captchaVal: true,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    const captchaPassed = this.state.captchaVal

    if (captchaPassed) {
      const url = `https://docs.google.com/forms/d/e/1FAIpQLScJ0RDEmj7bbjtT35J31fXJ70HvQFOm6ZYSdiCjHbf6_60SqA/formResponse?usp=pp_url&entry.1679574114=${this.state.name}&entry.1771109062=${this.state.email}&entry.1075185580=${this.state.message}`
      window.open(url, "_self")
      this.setState({
        name: "",
        email: "",
        message: "",
        captchaVal: false,
      })
      window.open("#", "_blank")
      console.log("verification passed")
    } else {
      alert("Verification failed. Please verify by checking the checkbox")
    }
  }
  render() {
    return (
      <FormContainer onSubmit={this.handleSubmit}>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js" async defer />
        </Helmet>
        <h2>We’d love to hear from you</h2>
        <p>
          Whether you have a question about our products, pricelist, career
          opportunites, or anything else, our team is ready to answer your
          questions.
        </p>
        <FormInputContainer>
          <InputContainer>
            <label htmlFor="name">
              <span>
                Name <span>*</span>
              </span>
              <input
                id="name"
                name="name"
                type="text"
                value={this.state.name}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <InputContainer>
            <label htmlFor="email">
              <span>
                Email <span>*</span>
              </span>
              <input
                id="email"
                name="email"
                type="email"
                value={this.state.email}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <InputContainer>
            <label htmlFor="message">
              <span>
                Message <span>*</span>
              </span>
              <textarea
                id="message"
                name="message"
                rows="8"
                value={this.state.message}
                onChange={this.handleInputChange}
                required
              />
            </label>
          </InputContainer>
          <SubmitButton>
            <ReCAPTCHA
              sitekey="6Lf9GBoaAAAAACO3Jyky-zvSutmQej0LQgQQjR30"
              onChange={this.handleCaptchaVerification}
            />
            <input type="submit" value="Submit" />
          </SubmitButton>
        </FormInputContainer>
        <Details />
      </FormContainer>
    )
  }
}

Contact.propTypes = {
  contactHeading: PropTypes.string,
  contactPageHeading: PropTypes.string,
  contactPageSubHeading: PropTypes.string,
}

Contact.defaultProps = {
  contactHeading: ``,
  contactPageHeading: ``,
  contactPageSubHeading: ``,
}
